<template>
    <section class="appointment_list_wrp">
        <div class="container-fluid">
            <div class="appointment_list_header_wrp">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <h1 class="appointment_list_heading">
                            <router-link class="btn back_btn" to="/dashboard/">
                                <i aria-hidden="true" class="fa fa-chevron-left"></i>
                            </router-link> My Doctors
                        </h1>
                    </div>
                </div>
            </div>
            <div class="appointment_list_body_wrp">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-left">Doctor Name</th>
                            <th class="text-left">Patient Name</th>
                            <th>Time</th>
                            <th class="text-right">Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="recentAppointmentInfoResults.length > 0">
                        <tr v-for="(item, index) in recentAppointmentInfoResults" :key="index">
                            <td class="text-left doctor_name_link_wrp">
                                <router-link :to="{ name: 'appointmentDetail', params: { appointmentId: item.id } }">
                                    Dr {{ item.doctor_detail.data.first_name }}
                                    {{ item.doctor_detail.data.last_name }}</router-link>
                            </td>
                            <td>{{ item.full_name }}</td>
                            <td>{{ item.appointment_time | LocalTimeFormat }} <br> {{ item.appointment_time |
                                LocalDateFormat }}</td>
                            <td class="status_btn_wrp text-right">
                                <template v-if="item.is_approved">
                                    <router-link :to="{ name: 'appointmentDetail', params: { appointmentId: item.id } }"
                                        class="btn btn-primary mr-0">Consultation Ongoing</router-link>
                                </template>
                                <template v-else>
                                    <button class="btn btn-info">Pending</button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">
                                <div class="no_data_in_info_box_wrp">
                                    <h4>You don't have any appointments</h4>
                                    <router-link :to="{ name: 'doctorsList' }" class="btn btn-default">Book Your First
                                        Appointment
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            recentAppointmentInfo: null,
            recentAppointmentInfoResults: []
        }
    }

}
</script>
<style lang="scss" scoped>
.appointment_list_wrp {
    .appointment_list_header_wrp {
        padding-bottom: 25px;

        .back_btn {
            border-radius: 35px;
            padding: 0;
            margin-right: 5px;
            font-size: 16px;
            display: inline-block;
            vertical-align: text-bottom;
            line-height: 32px;
            width: 32px;
            height: 32px;
            outline: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
        }

        .appointment_list_heading {
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0;
        }

        .online_appointment_searchbar {
            position: relative;
            display: inline-block;
            vertical-align: middle;

            .fa {
                font-size: 18px;
                cursor: pointer;
                color: #c0c0c0;
                position: absolute;
                top: 8px;
                left: 10px;
            }

            .form-control {
                background: rgba(228, 233, 237, 0.1);
                box-shadow: -5px 5px 10px rgba(82, 106, 133, 0.06);
                font-size: 18px;
                color: #fff;
                font-family: 'MyriadProRegular', sans-serif;
                border: none;
                height: auto;
                width: 280px;
                line-height: 36px;
                padding: 0px 15px 0 40px;
                -webkit-box-shadow: none;
                box-shadow: none;

                &::-moz-placeholder {
                    color: #dfdfdf;
                }

                &::-webkit-input-placeholder {
                    color: #dfdfdf;
                }

                &:focus::-webkit-input-placeholder {
                    font-size: 0px;
                }

                &:focus::-moz-placeholder {
                    font-size: 0px;
                }
            }
        }
    }

    .appointment_list_body_wrp {
        .table {
            margin: 0px;
            letter-spacing: 0.5px;
            color: #ffffff;
            border-collapse: separate;
            border-spacing: 0 3px;

            thead {
                th {
                    border-bottom: 0;
                    font-size: 19px;
                    padding: 10px 8px;
                    font-family: 'MyriadProRegular', sans-serif;
                    text-transform: uppercase;
                    border-top: 0;
                    transition: all .5s ease;
                    -webkit-transition: all .5s ease;
                    -moz-transition: all ease .5s;
                    background: rgba(228, 233, 237, .1);
                    color: #87cefa;
                }

                .empty_cell th {
                    padding: 0px !important;
                    background: none !important;
                }
            }

            tbody {
                td {
                    color: #fff;
                    vertical-align: middle;
                    padding: 10px 8px;
                    font-size: 20px;
                    font-family: 'MyriadProRegular', sans-serif;
                    transition: all .5s ease;
                    -webkit-transition: all .5s ease;
                    -moz-transition: all ease .5s;
                    height: 70px;
                    line-height: 1.3;
                    border-top: 0;
                    background: rgba(228, 233, 237, .1);

                    &.symptoms_item span:last-child i {
                        display: none;
                    }

                    a {
                        color: #fff;
                        text-decoration: none;

                        &:hover {
                            color: #ffd400;
                        }
                    }
                }
            }
        }
    }

    .status_btn_wrp {
        .btn {
            padding: 8px 10px;
            line-height: 1;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .btn-danger {
        &:hover {
            background: #87cefa;
            color: #000 !important;
            border-color: #87cefa;
        }
    }

    .payment_status_text {
        font-size: 16px;
        display: block;
        color: #ffd400;
        margin-top: 4px;
    }

    .payment_inpro_btn {
        background: #ffd400 !important;
        border: none !important;
        color: #000 !important;

        &:hover {
            background: #87cefa !important;
        }
    }

    @media only screen and (max-width:767px) {
        .container-fluid {
            padding: 0px;
        }
    }

    .no_data_in_info_box_wrp {
        text-align: center;
        padding:25px 0;

        h4 {
            font-family: 'MyriadProRegular', sans-serif;
            font-size: 20px;
            color: #fffeea;
            margin-bottom: 20px;

            @media only screen and (max-width:767px) {
                font-size: 18px;
            }
        }

        .btn-default {
            background: rgba(255, 255, 255, 0.12);
            border: none;
            color: #ffd400;
            font-family: 'MyriadProRegular', 'Lato', sans-serif;
            box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.05), -1px -1px 1px rgba(255, 255, 255, 0.05);
            outline: none !important;

            &:hover {
                background: #87cefa;
                color: #000000 !important;
            }
        }

        @media only screen and (max-width:767px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
}
</style>